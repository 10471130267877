import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/painel",
      meta: { auth: true },
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          name: "painel",
          path: "/painel",
          component: () => import("@/view/pages/painel/list.vue")
        },
        {
          name: "portaldogestor",
          path: "/gestor/unidadesgestoras",
          component: () => import("@/view/pages/gestor/index.vue"),
          children: [
            {
              name: "unidadegestoras",
              path: "/gestor/unidadesgestoras",
              component: () => import("@/view/pages/gestor/list.vue")
            },
            {
              name: "unidadegestoraremessas",
              path: "/gestor/remessas/:unidadeGestoraId",
              component: () => import("@/view/pages/gestor/remessas.vue"),
              props: { page: "Remessas" }
            },
            {
              name: "balancetes",
              path: "/gestor/balancetes/:unidadeGestoraId/:balanceteId",
              component: () => import("@/view/pages/gestor/balancetes.vue")
            },
            {
              name: "regrasdeintegridade",
              path: "/gestor/regrasintegridade/:unidadeGestoraId/:balanceteId",
              component: () =>
                import("@/view/pages/gestor/regrasIntegridade.vue"),
              props: { page: "Regras de Integridade" }
            }
          ]
        },
        {
          name: "unidadesgestoras",
          path: "/unidadesgestoras",
          component: () => import("@/view/pages/managingUnit/list.vue")
        },
        {
          name: "users",
          path: "/users",
          component: () => import("@/view/pages/user/list.vue")
        },
        {
          name: "remessa",
          path: "/remessa/:id",
          component: () => import("@/view/pages/envioRemessa/add.vue")
        },
        {
          name: "periodoremessas",
          path: "/periodoremessas",
          component: () => import("@/view/pages/remittancesPeriod/list.vue"),
          props: { page: "Admin" }
        },
        {
          name: "perfil",
          path: "/perfil",
          component: () => import("@/view/pages/Perfil.vue")
        },
        {
          name: "layoutsremessa",
          path: "/layoutsremessa",
          component: () => import("@/view/pages/layoutsRemessa/list.vue"),
          props: { page: "Admin" }
        },
        {
          name: "remessas",
          path: "/remessas",
          component: () => import("@/view/pages/remessas/list.vue")
        },
        {
          name: "remessasestruturantes",
          path: "/remessas-estruturantes",
          component: () => import("@/view/pages/continuousRemittances/list.vue")
        },
        {
          name: "relatorideinconsistencia",
          path: "/relatorio-de-inconsistencia",
          component: () =>
            import("@/view/pages/relatorioInconsistencias/list.vue")
        },
        {
          name: "processamento",
          path: "/processamento",
          component: () => import("@/view/pages/Processamento/list.vue")
        },
        {
          name: "questionarios",
          path: "/questionarios",
          component: () => import("@/view/pages/questionarios/list.vue")
        },
        {
          name: "responder-questionario",
          path: "/responderquestionario/:respostaId",
          component: () => import("@/view/pages/questionarios/edit.vue")
        },
        {
          name: "personificar",
          path: "/personificar",
          component: () => import("@/view/pages/impersonate/FetchUser.vue")
        },
        {
          name: "diligencia",
          path: "/diligencias",
          component: () => import("@/view/pages/diligence/list.vue")
        },
        {
          name: "visualizar_diligencia",
          path: "/diligencias/:id",
          component: () => import("@/view/pages/diligence/view.vue")
        },
        {
          name: "extracao",
          path: "/extracao",
          component: () => import("@/view/pages/extraction/view.vue")
        },
        {
          name: "visualizar_extracao",
          path: "/extracao/:id",
          component: () => import("@/view/pages/extraction/view_details.vue")
        },
        {
          name: "show_data",
          path: "/remessas/show_data/:id",
          component: () => import("@/view/pages/envioRemessa/show_data.vue")
        },
        {
          name: "show_data_details",
          path: "/remessas/show_data_details/:id",
          component: () =>
            import("@/view/pages/envioRemessa/show_data_details.vue")
        }
      ]
    },
    {
      path: "/",
      redirect: "/login",
      meta: { auth: false },
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login")
        },
        {
          name: "verify",
          path: "verify/:token",
          component: () => import("@/view/pages/auth/Verify")
        },
        {
          name: "update",
          path: "/update/:token",
          component: () => import("@/view/pages/auth/UpdatePassword")
        },
        {
          name: "confirm",
          path: "/confirm",
          component: () => import("@/view/pages/auth/ConfirmData")
        },
        {
          name: "recover_password",
          path: "/esqueceu_senha",
          component: () => import("@/view/pages/auth/RecoverPassword")
        },
        {
          name: "periodoremessasportal",
          path: "/calendario-de-remessas",
          component: () => import("@/view/pages/remittancesPeriod/list.vue"),
          props: { page: "Portal" }
        },
        {
          name: "layoutsremessaportal",
          path: "/visualizar-layouts-de-remessa",
          component: () => import("@/view/pages/layoutsRemessa/list.vue"),
          props: { page: "Portal" }
        },
        {
          name: "remittance_delivery_report",
          path: "/relatorio-acompanhamento-remessa",
          component: () => import("@/view/pages/remittanceDeliveryReport/list")
        },
        {
          name: "frequently_asked_questions",
          path: "/duvidas-frequentes",
          component: () => import("@/view/pages/frequentlyAskedQuestions/list")
        },
        {
          name: "relatorioExemplo",
          path: "/relatorio-exemplo",
          component: () => import("@/viewReports/pages/reportsExample/list.vue")
        },
        {
          name: "remittance_status_period_report",
          path: "/relatorio-status-remessa-periodo",
          component: () =>
            import("@/viewReports/pages/remittanceStatusPeriodReport/list")
        }
      ]
    },
    {
      path: "/portal",
      component: () => import("@/view/pages/portal/Portal.vue")
    },
    {
      name: "error",
      path: "/error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // rota 404, quando nenhuma nao acessa nenhuma rota configurada acima
      name: "404",
      path: "/404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
