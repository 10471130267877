import axios from "axios";
import Toaster from "../components/Toaster";

/**
 * Mostra a resposta no toaster, caso venha mensagem do backend.
 *
 * @param Response response dados que foram enviados
 *
 */

function responseHandler(response) {
  if (!response.data.hasOwnProperty("message")) {
    return;
  }

  // Returns nothing in case of unauthenticated user
  if (response.status === 401) {
    return;
  }

  if (response.status === 500) {
    response.data.status = "error";
  }

  const data = response.data;
  const status = data.status;
  const message = data.message;
  let title;

  switch (status) {
    case "success":
      title = "Sucesso";
      break;
    case "error":
      title = "Erro";
      break;
    case "info":
      title = "Nota:";
      break;
    case "warn":
      title = "Atenção";
      break;
    default:
      title = "";
      break;
  }

  Toaster.toast({ title: title, message: message, status: status });
}

axios.interceptors.response.use(
  (response) => {
    responseHandler(response);
    return response;
  },
  (error) => {
    if (error.toString().includes("Cancel")) {
      return Promise.reject(error);
    }

    if (
      !error.config.hasOwnProperty("errorHandle") ||
      error.config.errorHandle !== false
    ) {
      responseHandler(error.response);
    }

    return Promise.reject(error.response);
  }
);

export default responseHandler;
