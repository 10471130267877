import axios from "axios";
import Vue from "vue";
import Toaster from "@/components/Toaster";

// Add a response interceptor to redirect to login if expires session(401 Unauthorized)
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let counter = 0;
    if (
      error &&
      error.data &&
      error.data.message &&
      error.data.message.includes("REST API - Token invalido.")
    ) {
      Toaster.toast({
        message: error.data.message,
        status: "warn"
      });
    }
    if (
      error &&
      error.status === 401 &&
      Vue.router.currentRoute.path !== "/login"
    ) {
      Vue.auth.logout();
      if (counter === 0) {
        Vue.router.replace("/login");
        Toaster.toast({
          message: "Você não está autenticado ou seu login expirou.",
          status: "warn"
        });
        counter++;
      }
    } else {
      return Promise.reject(error);
    }
  }
);
