/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");

window.Vue = require("vue");

// Main JS Metronic Vue imports and settings

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import VueAuth from "@websanova/vue-auth";
import authBearer from "@websanova/vue-auth/dist/drivers/auth/bearer.esm.js";
import httpAxios from "@websanova/vue-auth/dist/drivers/http/axios.1.x.esm";
import authRouter from "@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm";

import App from "./App.vue";
import router from "./router";
import store from "./core/services/store";
import { clearPendingRequests } from "./services/cancel_duplicate_requests";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

import VuetifyDialog from "vuetify-dialog";
import "vuetify-dialog/dist/vuetify-dialog.css";

// Vue 3rd party plugins
import i18n from "./core/plugins/vue-i18n";
import vuetify from "./core/plugins/vuetify";
import "./core/plugins/portal-vue";
import "./core/plugins/perfect-scrollbar";
import "./core/plugins/inline-svg";
import "./core/plugins/apexcharts";
import "./core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";

// sanctum configuration
axios.defaults.withCredentials = true;

Vue.router = router;

Vue.use(VueAxios, axios);
Vue.use(VuetifyDialog, {
  context: {
    vuetify
  }
});
Vue.axios.defaults.baseURL = window.API_BASE_URL;

// vue authentication
Vue.use(VueAuth, {
  auth: authBearer,
  http: httpAxios,
  router: authRouter,
  refreshData: { enabled: false }
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  clearPendingRequests();

  next();

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

// Message responseHandler
import "./services/message_handler";
import "./services/session_expired_handler";
import "./services/cancel_duplicate_requests";

require("./global_filters.js");

// eslint-disable-next-line no-unused-vars
const app = new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");

if (window.AUTH_USER) {
  const auth = app.$auth;
  setTimeout(() => {
    if (!auth.check()) {
      auth.user(window.AUTH_USER);
      auth.token(null, "Auth Token");
      app.$router.push({ name: "painel" });
    }
  }, 500);
}
