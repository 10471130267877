import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify)
import pt from 'vuetify/src/locale/pt.ts';

export default new Vuetify({
  lang: {
    current:  'pt',
    locales: { pt }
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#3B59A4",
        secondary: "#FFFFFF",
        terciary: "#FFFFFF",
        accent: "#5d78ff",
        error: "#CB1912",
        danger: "#CB1912",
        info: "#329BDF",
        success: "#27A521",
        warning: "#FDB22B"
      }
    }
  }
});
