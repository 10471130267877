<template>
  <router-view></router-view>
</template>

<script>
  import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";

  export default {
    name: "SIAP",
    mounted() {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static json (@/core/config/layout.config.json)
       */
      this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
    }
  };
</script>

<style lang="scss">
  // 3rd party plugins css
  @import "~perfect-scrollbar/css/perfect-scrollbar.css";
  @import "~socicon/css/socicon.css";
  @import "~@fortawesome/fontawesome-free/css/all.css";
  @import "~line-awesome/dist/line-awesome/css/line-awesome.css";
  @import "@/assets/sass/plugins/flaticon/flaticon";
  @import "@/assets/sass/plugins/flaticon2/flaticon";
  @import "@/assets/sass/plugins/keenthemes-icons/font/ki";

  // @import "@/assets/sass/medialibrary_styles";

  // Main demo style scss
  @import "@/assets/sass/style.vue";
  @import "../sass/global.scss";
</style>
