<script>
  import miniToastr from "mini-toastr";

  const toastTypes = {
    success: "success",
    error: "error",
    info: "info",
    warn: "warn"
  };

  miniToastr.init({
    types: toastTypes,
    timeout: 5000,
    style: {}
  });

  miniToastr.setIcon("error", "span", {
    class: "icon-error material-icons-outlined"
  });
  miniToastr.setIcon("info", "span", {
    class: "icon-info material-icons-outlined"
  });
  miniToastr.setIcon("success", "span", {
    class: "icon-success material-icons-outlined"
  });
  miniToastr.setIcon("warn", "span", {
    class: "icon-warn material-icons-outlined"
  });

  // Funcao para mudar a estrutura do ícone para o mdi
  var changeIcon = function (item, value) {
    if (!item) return;
    for (var i = 0; i < item.length; i++) {
      item[i].innerHTML = value;
    }
  };

  import FontFaceObserver from "fontfaceobserver";

  const loadIcons = function () {
    var font = new FontFaceObserver("Material Icons Outlined");
    font.load().then(function () {
      let icons = document.querySelectorAll(".mini-toastr-notification__icon");
      for (var i = 0; i < icons.length; i++) {
        icons[i].classList.add("icons-loaded");
      }
    });
  };

  /**
   * Mostra a mensagem com o toaster, dependendo do tipo de
   * status.
   *
   */
  const toast = function ({ title, message, status, timeout, cb }) {
    if (status === "success") {
      miniToastr.success(message, title, timeout, cb);
      let toastrSuccess = document.querySelectorAll(
        ".icon-success.mini-toastr-notification__icon"
      );
      changeIcon(toastrSuccess, "check_circle_outline");
    }
    if (status === "error") {
      miniToastr.error(message, title, timeout, cb);
      let toastrError = document.querySelectorAll(
        ".icon-error.mini-toastr-notification__icon"
      );
      changeIcon(toastrError, "error_outline");
    }
    if (status === "info") {
      miniToastr.info(message, title, timeout, cb);
      let toastrInfo = document.querySelectorAll(
        ".icon-info.mini-toastr-notification__icon"
      );
      changeIcon(toastrInfo, "info");
    }
    if (status === "warn") {
      miniToastr.warn(message, title, timeout, cb);
      let toastrWarn = document.querySelectorAll(
        ".icon-warn.mini-toastr-notification__icon"
      );
      changeIcon(toastrWarn, "warning_amber");
    }
    loadIcons();
  };

  export default {
    toast
  };
</script>

<style lang="scss">
  #mini-toastr {
    position: fixed;
    z-index: 99999;
    right: 12px;
    top: 12px;
  }
  .mini-toastr__notification {
    cursor: pointer;
    position: relative;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    min-width: 500px;
    max-width: 845px;
    color: #fafafa;
    padding: 20px 48px;
    margin: 0 0 6px 0;
    border-radius: 8px;
    background-color: #434343;
  }
  .mini-toastr-notification__title {
    display: none;
    font-weight: 700;
    margin-left: 12px;
  }
  .mini-toastr-notification__message {
    display: inline-block;
    vertical-align: middle;
    min-width: 460px;
    padding: 0 12px;
  }
  .mini-toastr-notification__icon {
    display: none;
    width: 23px;
    overflow: hidden;
  }
  .mini-toastr-notification__icon.icons-loaded {
    display: block;
  }
  .mini-toastr__notification.-error {
    background-color: #dc2d1e;
    color: #fafafa;
  }
  .mini-toastr__notification.-warn {
    background-color: #fdb22b;
    color: #262626;
  }
  .mini-toastr__notification.-success {
    background-color: #27a521;
    color: #262626;
  }
  .mini-toastr__notification.-info {
    background-color: #329bdf;
    color: #262626;
  }
  .icon-success,
  .icon-warn,
  .icon-info,
  .icon-error {
    top: 20px;
    position: absolute;
    left: 21px;
    font-size: 23px;
  }
  .icon-success {
    color: #262626;
  }
  .icon-warn {
    color: #262626;
  }
  .icon-info {
    color: #262626;
  }
  .icon-error {
    color: #fafafa;
    font-size: 22px;
  }
</style>
