import Vue from "vue";

Vue.filter("formatDate", function (date) {
  if (!date) {
    return "";
  }

  date = date.substring(0, 10);

  const [year, month, day] = date.split("-");
  return `${day}/${month}/${year}`;
});

Vue.filter("formatDateTime", function (datetime) {
  if (!datetime) {
    return "";
  }

  const [date, time] = datetime.split(" ");

  const [year, month, day] = date.split("-");
  return `${day}/${month}/${year} ${time}`;
});

Vue.filter("formatMoney", function (value) {
  value = parseFloat(value);
  if (!value) {
    return "R$ 0,00";
  }

  return value.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL"
  });
});

Vue.filter("formatCNPJ", function (value) {
  return value.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    "$1.$2.$3/$4-$5"
  );
});
