import axios from "axios";

// Declare a Map to store the identification and cancellation functions for each request
const pending = new Map();

const addPendingRequests = (config) => {
  const url = [
    config.method,
    config.url,
    JSON.stringify(config.params),
    JSON.stringify(config.data)
  ].join("&");

  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken((cancel) => {
      if (!pending.has(url)) {
        // If the current request does not exist in pending, add it
        pending.set(url, cancel);
      }
    });
};
/**
 * Remove Request
 * @param {Object} config
 */
const removePendingRequests = (config) => {
  const url = [
    config.method,
    config.url,
    JSON.stringify(config.params),
    JSON.stringify(config.data)
  ].join("&");

  // If the current request identity exists in pending, you need to cancel the current request and remove it
  if (pending.has(url)) {
    const cancel = pending.get(url);
    cancel(url);
    pending.delete(url);
  }
};
/**
 * Empty requests in pending (called on route jumps)
 */
export const clearPendingRequests = () => {
  for (const [url, cancel] of pending) {
    cancel(url);
  }
  pending.clear();
};

axios.interceptors.request.use(
  (config) => {
    removePendingRequests(config); // Check previous requests to cancel before the request starts
    addPendingRequests(config); // Add current request to pending
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    removePendingRequests(response); // Remove this request at the end of the request
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
