// USA
export const locale = {
  TRANSLATOR: {
    SELECT: "Selecione seu idioma"
  },
  MENU: {
    NEW: "novo",
    ACTIONS: "Ações",
    CREATE_POST: "Criar Novo Post",
    PAGES: "Páginas",
    FEATURES: "Recursos",
    APPS: "Apps",
    DASHBOARD: "Painel"
  },
  AUTH: {
    GENERAL: {
      OR: "Ou",
      SUBMIT_BUTTON: "Enviar",
      NO_ACCOUNT: "Não possui uma conta?",
      SIGNUP_BUTTON: "Registrar",
      FORGOT_BUTTON: "Esqueceu a Senha",
      BACK_BUTTON: "Voltar",
      PRIVACY: "Privacidade",
      LEGAL: "Legal",
      CONTACT: "Contato"
    },
    LOGIN: {
      TITLE: "Conta de Login",
      BUTTON: "Acessar"
    },
    FORGOT: {
      TITLE: "Esqueceu a Senha?",
      DESC: "Insira seu e-mail para resetar a senha",
      SUCCESS: "Sua conta foi resetada com sucesso."
    },
    REGISTER: {
      TITLE: "Registrar",
      DESC: "Insira seus dados para criar sua conta",
      SUCCESS: "Sua conta foi registrada com sucesso."
    },
    INPUT: {
      EMAIL: "E-mail",
      FULLNAME: "Nome Completo",
      PASSWORD: "Senha",
      CONFIRM_PASSWORD: "Confirmar Senha",
      USERNAME: "Usuário"
    },
    VALIDATION: {
      INVALID: "{{name}} é inválido",
      REQUIRED: "{{name}} é obrigatório",
      MIN_LENGTH: "{{name}} o mínimo de caracteres é {{min}}",
      AGREEMENT_REQUIRED: "É necessário aceitar os termos & condições",
      NOT_FOUND: "O {{name}} requerido não foi encontrado",
      INVALID_LOGIN: "Os dados de login estão incorretos",
      REQUIRED_FIELD: "Campo obrigatório",
      MIN_LENGTH_FIELD: "Quantia mínima de caracteres:",
      MAX_LENGTH_FIELD: "Quantia máxima de caracteres:",
      INVALID_FIELD: "O campo é inválido"
    }
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Contagem de registros selecionados: ",
      ALL: "Todos",
      SUSPENDED: "Suspensos",
      ACTIVE: "Ativos",
      FILTER: "Filtrar",
      BY_STATUS: "por Estado",
      BY_TYPE: "por Tipo",
      BUSINESS: "Negócio",
      INDIVIDUAL: "Individual",
      SEARCH: "Buscar",
      IN_ALL_FIELDS: "em todos os campos"
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Clientes",
      CUSTOMERS_LIST: "Lista de clientes",
      NEW_CUSTOMER: "Novo Cliente",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Remover Cliente",
        DESCRIPTION: "Tem certeza que deseja remover permanentemente este cliente?",
        WAIT_DESCRIPTION: "O cliente está sendo removido...",
        MESSAGE: "O cliente foi removido"
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Remover Cliente",
        DESCRIPTION: "Tem certeza que deseja remover permanentemente os clientes selecionados?",
        WAIT_DESCRIPTION: "Os clientes estão sendo removidos...",
        MESSAGE: "Os clientes selecionados foram removidos"
      },
      UPDATE_STATUS: {
        TITLE: "Os status foram atualizados para os clientes selecionados",
        MESSAGE: "Os status dos clientes selecionados foram atualizados com sucesso"
      },
      EDIT: {
        UPDATE_MESSAGE: "O cliente foi atualizado",
        ADD_MESSAGE: "O cliente foi criado"
      }
    }
  }
};
